.singin-form {
  max-width: 500px;
  margin: auto;
  background: #fff;
  padding: 30px;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 20%);
  border-radius: 5px;
}

.singin-form h1 {
  display: block;
  text-align: center;
  width: 100%;
  font-size: 22px;
  margin-bottom: 20px;
}
